export const CARD_TEXT = {
  BUY: 'Mua ngay',
  FREESHIP: 'Miễn phí giao hàng HCM',
  OUT_OF_STOCK: 'Hết hàng',
  CONTACT: 'Liên hệ',
};

export const MODAL_TEXT = {
  QUANTITY: 'Số lượng',
  ADD_TO_CART: 'Thêm vào giỏ hàng',
  BUY: 'Mua ngay',
  OUT_OF_STOCK: 'Hết hàng',
  DESCRIPTION: 'CHI TIẾT SẢN PHẨM',
  VIEW_DESCRIPTION: 'Xem chi tiết',
  CONTACT: 'Liên hệ',
  CONTACT_PRICE_MESSAGE:
    'Văn Phòng Phẩm Sơn Thủy sẽ liên hệ trực tiếp với khách hàng để xác nhận giá bán sản phẩm sau khi đơn hàng được đặt thành công',
};
