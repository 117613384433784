import React, { useRef, useState, useCallback, useEffect, memo } from 'react';

import ProductCard from './components/card';
import ProductModal from './components/product-modal';

import { useRouter } from 'next/router';
import useTimeout from '@restart/hooks/useTimeout';
import useLayoutContext from '@views/layouts/hooks/use-context';

import { useAddCartItemMutation } from '@views/modules/cart/hooks/use-cart';
import { INTERNAL_ROUTES } from '@helpers/route';
import { DELAY } from '@helpers/const';
import _scrollToTop from '@helpers/scroll-top';
import _get from 'lodash/get';
import { useAlert } from 'react-alert';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';

export type TProductCardProps = {
  id: string | number;
  name?: string;
  image?: string;
  imageSet?: string;
  price?: number;
  listPrice?: number;
  quantityAvailable: number;
  hasVideo?: boolean;
  isContactProduct?: boolean;
  isInterested?: boolean;
  isRow?: boolean;

  dataCategory?: string;
  dataAction?: string;
  dataLabel?: string;
  dataClassName?: string;
  nofollow?: boolean;
};

enum ActionType {
  ExpressBuy = 'ExpressBuy',
  AddToCart = 'AddToCart',
}

export const ProductCardContainer = memo<TProductCardProps>(
  (props): JSX.Element => {
    const { id } = props;

    const route = useRouter();
    const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(useBreakpoint());
    const alert = useAlert();

    const {
      authenticated,
      checkout: { handleAddItem },
      setActiveCartToast,
    } = useLayoutContext();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const cartToastTimer = useTimeout();

    const onBuyClick = useCallback(() => {
      setSubmitting(true);
      dispatchAction(ActionType.ExpressBuy);
    }, [isSubmitting]);

    const onAddCartClick = useCallback(() => {
      setSubmitting(true);
      dispatchAction(ActionType.AddToCart);
    }, [isSubmitting, setActiveCartToast, props]);

    const onViewClick = useCallback(() => {
      setShowModal(true);

      // console.log('productClick', props.name);
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: 'productClick',
        ecommerce: {
          click: {
            products: [
              {
                name: props.name, // Name or ID is required.
                id: props.id,
                price: props.price,
                brand: '',
                category: props.dataCategory,
                variant: '',
                position: 0,
              },
            ],
          },
        },
      });
    }, [showModal, props]);

    const onClose = useCallback(() => {
      setShowModal(false);
    }, [showModal]);

    const handleSuccess = useCallback(
      (redirect: boolean = false) => {
        setSubmitting(false);
        _scrollToTop();
        if (isMobile) {
          alert.info('Thêm vào giỏ hàng thành công');
        } else {
          setActiveCartToast(true);
          cartToastTimer.set(() => {
            setActiveCartToast(false);
          }, DELAY);
        }

        if (route.pathname !== INTERNAL_ROUTES.CART && redirect) {
          cartToastTimer.set(() => {
            route.push(INTERNAL_ROUTES.CART);
          }, 2000);
        }
      },
      [cartToastTimer, route, isMobile, setActiveCartToast]
    );

    const onSubmitModal = useCallback(({ quantity, isRedirect }) => {
      setShowModal(false);
      setSubmitting(true);
      dispatchAction(
        isRedirect ? ActionType.ExpressBuy : ActionType.AddToCart,
        quantity <= 0 ? 1 : quantity
      );
    }, []);

    const dispatchAction = useCallback(
      async (actionClick: ActionType, quantity = 1) => {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'VND',
            add: {
              // 'add' actionFieldObject measures.
              products: [
                {
                  //  adding a product to a shopping cart.
                  name: props.name,
                  id: props.id,
                  price: props.price,
                  brand: '',
                  category: props.dataCategory,
                  variant: '',
                  quantity,
                },
              ],
            },
          },
        });

        try {
          await handleAddItem({ id: parseInt(id as string), quantity });
          handleSuccess(actionClick === ActionType.ExpressBuy);
        } catch (error) {
          alert.error(
            _get(
              error,
              'message',
              _get(
                error,
                'graphQLErrors[0].message',
                'Có lỗi xảy ra vui lòng thử lại'
              )
            )
          );
        }
      },
      [props]
    );

    return (
      <>
        <ProductCard
          {...props}
          onBuyClick={onBuyClick}
          onAddCartClick={onAddCartClick}
          onViewClick={onViewClick}
        />

        {showModal && (
          <ProductModal
            id={id}
            onClose={onClose}
            onSubmit={onSubmitModal}
            isAuthorized={authenticated}
          />
        )}
      </>
    );
  }
);
ProductCardContainer.displayName = 'ProductCardContainer';
export default ProductCardContainer;
