import Link from 'next/link';
import {
  IFooterCompanyProps,
  IFooterLinkProps,
} from '@views/components/app/Footer';
import { IBannerItemProps } from '@views/components/widget/BannerTop';

const isProd = process.env.NODE_ENV === 'production';
const HOST_URL = isProd ? process.env.HOST_URL : '';

export const BANNER_ADS: IBannerItemProps = {
  key: '_four_',
  src: `${HOST_URL}/assets/banner-4/banner 8_1200x67px.jpg`,
  url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  srcSet: `${HOST_URL}/assets/banner-4/banner 8_2400x136px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 8_3600X204px.jpg 3x`,
  alt: 'Văn Phòng Phẩm Sơn Thủy',
};

export const BANNER_SLIDES: IBannerItemProps[] = [
  {
    key: '_firs2t_', 
    src: `${HOST_URL}/assets/banner-paper/banner_paper1.jpg`,
    url:
      'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
    alt: 'Giấy in Bill - Giấy in Nhiệt - Băng Keo',
  },
  {
    key: '_fir23st_',
    src: `${HOST_URL}/assets/banner-paper/banner_paper2.jpg`,
    url:
      'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
    alt: 'Giấy in Bill - Giấy in Nhiệt - Băng Keo',
  },
  {
    key: '_fir32st_',
    src: `${HOST_URL}/assets/banner-paper/banner_paper3.jpg`,
    url:
      'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
    alt: 'Giấy in Bill - Giấy in Nhiệt - Băng Keo',
  },
  // {
  //   key: '_fir12st_',
  //   src: `${HOST_URL}/assets/banner-paper/banner_paper4.jpg`,
  //   url:
  //     'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
  //   alt: 'Giấy in Bill - Decal Nhiệt - Máy in đơn hàng Shopee Lazada',
  // },
  // {
  //   key: '_first_',
  //   src: `${HOST_URL}/assets/banner-paper/banner_paper5.jpg`,
  //   url: 'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
  //   alt: 'Giấy in Bill - Decal Nhiệt - Máy in đơn hàng Shopee Lazada',
  // },
  // {
  //   key: '_fir32st_',
  //   src: `${HOST_URL}/assets/banner-paper/banner_paper6.jpg`,
  //   url:
  //     'https://vanphongphamsonthuy.com/danh-muc/giay-in-bill---decal-nhiet---may-in-don-hang-shopee-lazada-1574.html',
  //   alt: 'Giấy in Bill - Decal Nhiệt - Máy in đơn hàng Shopee Lazada',
  // },
  // {
  //   key: '3',
  //   src: `${HOST_URL}/assets/banner/banner_ST_1080x350px 3.jpg`,
  //   url:
  //     'https://vanphongphamsonthuy.com/danh-muc/but---muc-chat-luong-cao--1483.html',
  //   alt: 'Văn Phòng Phẩm Sơn Thủy',
  // },
  // {
  //   key: '4',
  //   src: `${HOST_URL}/assets/banner/banner_ST_1080x350px 4.jpg`,
  //   url: '#',
  //   alt: 'Văn Phòng Phẩm Sơn Thủy',
  // },
];

export const BANNER_STICKY_AD: IBannerItemProps = {
  key: '_banner_ink_',
  src: `${HOST_URL}/assets/vertical-banner.jpg`,
  url: `${HOST_URL}/danh-muc/hop-muc-may-in-1209.html`,
  srcSet: `${HOST_URL}/assets/vertical-banner@2x.jpg 2x, ${HOST_URL}/assets/vertical-banner@3x.jpg 3x`,
  alt: 'Văn Phòng Phẩm Sơn Thủy',
};

export const FEATURE_LINKS = [
  {
    key: '_one_day_delivery_',
    imageSrc: `${HOST_URL}/assets/icon/icon-times.svg`,
    text: 'Giao hàng nhanh trong ngày',
  },
  {
    key: '_free_delivery_',
    imageSrc: `${HOST_URL}/assets/icon/icon-truck.svg`,
    text: 'Miễn phí giao hàng',
  },
  {
    key: '_discount_',
    imageSrc: `${HOST_URL}/assets/icon/icon-tag.svg`,
    text: 'Giảm giá',
  },
  {
    key: '_gift_',
    imageSrc: `${HOST_URL}/assets/icon/icon-gift.svg`,
    text: 'Quà tặng',
  },
  {
    key: '_return_',
    imageSrc: `${HOST_URL}/assets/icon/icon-loop.svg`,
    text: 'Đổi trả nhanh chóng',
  },
];

export const companyInfo: IFooterCompanyProps = {
  name: 'CÔNG TY TNHH SX TM DV VĂN PHÒNG PHẨM SƠN THỦY',
  address: (
    <>
      Trụ sở: Tầng 12 (1206), Citilight Tower, Số 45 Võ Thị Sáu, Phường Đa Kao,
      Quận 1, Thành phố Hồ Chí Minh
      <br />
      Địa chỉ kho: 671/3 Hồng Bàng, Phường 6, Quận 6, TP. HCM (Vui lòng gọi
      trước) <br />
    </>
  ),
  phone: '0707 467 697',
  supportPhone: '0345 341 359',
  email: 'vanphongphamsonthuy@gmail.com',
  link: 'https://vanphongphamsonthuy.com',
  paymentInfo: (
     <>
      Chính sách kiểm hàng: Quý khách có thể từ chối nhận hàng khi sản phẩm không đúng yêu cầu, hoặc có thể đổi trả sản phẩm tại kho công ty
      <br />
      Phương thức thanh toán: Quý khách nhận hàng có thể thanh toán tiền mặt hoặc chuyển khoản <br />
    </>
  ),
  copyright: (
    <>
      GPDKKD: 0317305956 do sở KH & ĐT TP.HCM cấp ngày 23/05/2022. Chịu trách nhiệm nội dung: Châu Văn Nguyên
      <br />
      Copyright ⓒ 2022{' '}
      <Link href="/">
        <a>https://vanphongphamsonthuy.com</a>
      </Link>
    </>
  ),
  facebook: 'https://www.facebook.com/vanphongphamsonthuy',
  zalo: 'https://zalo.me/0707467697',
};

export const supportLinks: IFooterLinkProps[] = [
  {
    key: '_first_',
    url: `${HOST_URL}/bai-viet/huong-dan-mua-van-phong-pham`,
    text: 'Hướng dẫn mua hàng',
  },
  {
    key: '_second_',
    url: `${HOST_URL}/bao-gia`,
    text: 'Báo giá',
  },
  {
    key: '_third_',
    url: `${HOST_URL}/bai-viet/chinh-sach-uu-dai`,
    text: 'Chính sách ưu đãi',
  },
  {
    key: '_four_',
    url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
    text: 'Chính sách chiết khấu',
  },
  {
    key: '_five_',
    url: `${HOST_URL}/bai-viet/cau-hoi-thuong-gap-ve-van-phong-pham-faqs`,
    text: 'Câu hỏi thường gặp',
  },
  // {
  //   key: '_six_',
  //   url: '//vanphongpham.fast.vn',
  //   text: 'Blogs',
  //   external: true,
  // },
];

export const policyLinks: IFooterLinkProps[] = [
  {
    key: '_first_',
    url: `${HOST_URL}/chinh-sach-bao-mat`,
    text: 'Chính sách bảo mật',
  },
  // {
  //   key: '_second_',
  //   url: '/bai-viet/huong-dan-mua-van-phong-pham',
  //   text: 'Chính sách mua hàng'
  // },
  {
    key: '_four_',
    url: `${HOST_URL}/bai-viet/huong-dan-doi-tra-hang-van-phong-pham`,
    text: 'Chính sách đổi trả',
  },
  {
    key: '_third_',
    url: `${HOST_URL}/bai-viet/chinh-sach-va-n-chuyen-giao-hang-mien-phi-van-phong-pham`,
    text: 'Chính sách giao hàng',
    // textAdvance: (
    //   <>
    //     Chính sách giao hàng <br />
    //     <strong>
    //       {'Đơn hàng > 1.5tr freeship ra chành xe và Nội thành (<10km)'}
    //     </strong>{' '}
    //     <br />
    //     <strong>{'Đơn hàng < 1.5tr + ship 30k (ship chành 40k)'}</strong>
    //   </>
    // ),
  },
  // {
  //   key: '_five_',
  //   url: '/',
  //   text: 'Chính sách thanh toán'
  // }
];

export const FREE_SHIP_TEXT = 'Miễn phí giao hàng HCM';
